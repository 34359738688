.online-comp{
    width: 100%;
    min-height: 200px;
    padding: 10px 10px 5px 20px;
    border-radius: 20px;
    background-color: #fff;
    margin-top: 10px;
}
.online-comp h2{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 30px;
    color: grey;
    font-weight: 500;
}
.online-people{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}
.online-people img{
    width: 45px;
    height: 45px;
    border-radius: 50px;
}
/* .online-people::before{
    content: "";
   position: absolute;
   background-color:green;
   width: 12px;
   height: 12px;
   top: 2px;
   left:34px;
   border-radius: 50%;
} */
@media screen and (max-width:1024px){
    .online-comp{
        width: 100%;
        min-height: 200px;
        padding: 10px 8px 10px 8px;
        border-radius: 10px;
        background-color: #fff;
        margin-top: 5px;
    }
    .online-comp h2{
        font-size: 14px;
        margin-bottom: 20px;
    }
    .online-people{
        margin-bottom: 16px;
        gap: 15px;
    }
    .online-people img{
        width: 40px;
        height: 40px;
    }
    .online-people::before{
       content: "";
       position: absolute;
       background-color:green;
       width: 10px;
       height: 10px;
       top: 2px;
       left:30px;
       border-radius: 50%;
    }
}
@media screen and (max-width:768px){
    .online-comp{
        width: 100%;
        min-height: 200px;
        padding: 10px 10px 5px 20px;
        border-radius: 20px;
        background-color: #fff;
        margin-top: 10px;
    }
    .online-comp h2{
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 30px;
        color: grey;
        font-weight: 500;
    }
    .online-people{
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
    }
    .online-people img{
        width: 45px;
        height: 45px;
        border-radius: 50px;
    }
    /* .online-people::before{
        content: "";
       position: absolute;
       background-color:green;
       width: 12px;
       height: 12px;
       top: 2px;
       left:34px;
       border-radius: 50%;
    } */
}