.info{
    width: 100%;
    min-height:500px ;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 20px 0px;
    border-radius: 20px;
    padding-bottom: 22px;
}
.info-cover{
    height: 100px;
    height: 240px;
    display: flex;
    flex-direction: column;
}
.info-cover img:nth-child(1){
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:center;
    border-radius:20px 20px 0px 0px;
    cursor: pointer;
}
.coverDiv{
    width: 40px;
    height: 40px;
    font-size: 30px;
    background-color:#fff;
    position: absolute;
    top: 30px;
    right: -10px;
    transform: translate(-50%,-50%); 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.profileDiv{
    width: 40px;
    height: 40px;
    background-color: #fff;
    position:absolute;
    top: 275px;
    left: 56%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.coverSvg,.profileSvg{
    font-size: 30px;
}

.info-cover img:nth-child(2){
    width: 160px;
    height:160px;
    border-radius: 50%;
    position: absolute;
    top: 240px;
    left: 50%;
    transform: translate(-50%,-50%);
    object-fit: cover;
    object-position: center;
    padding: 3px;
    background-color: #fff;
    cursor: pointer;
}

.info-follow h1{
    padding-top: 85px;
    text-align: center;
    font-weight: 400;
    font-size: 25px;
}
.info-follow p{
    text-align: center;
    font-weight: 400;
    color: grey;
    font-size: 16px;
}
.info-follow{
    min-height: 50%;
    padding: 0px 20px 0px;
}

.info-follow button{
    position: absolute;
    top: 250px;
    right: 10px;
    padding: 8px 13px;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    display: flex;
    gap: 8px;
    background-color: #dedede;
}
.info-follow button:hover{
    border: none;
    background: #1e5173;
    color: #fff;
  
}
.info-follow button svg{
    font-size: 21px;
}
.logout{
    position: absolute;
    top: 250px;
    left: 10px;
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 13px;
    background: #4B9CD3;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
}
.logout svg{
    font-size: 21px;
}

.info-details-list{
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    align-items: center;
}
.info-details-list span{
    color: grey;
    font-size: 16px;
}
.info-details{
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.info-col-1{
    flex: 1;
}
.info-col-2{
    flex: 1;
    display: flex;
    justify-content: space-evenly;
}
.info-col-2 div{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 2px;
}
.info-col-2 div h2{
    font-weight: 600;
    font-size: 20px;
}
.info-col-2 div span{
    font-size:15px ;
    color: grey;
}
@media screen and (max-width:1024px){
    .info{
        background-color: #ffffff;
        margin: 10px 0px;
        border-radius: 5px;
        padding-bottom: 22px;
    }
    .info-cover img:nth-child(1){
        border-radius:5px;
    }
}
@media screen and (max-width:768px){
    .info{
        margin:5px 0px;
        border-radius:5px;
    }
    .info-cover img:nth-child(1){
        border-radius:5px;
    }
    
}

@media screen and (max-width:430px){
   .info{
        margin:5px 0px;
        border-radius: 0px;
        min-height:100px ;
    }
    .info-cover{
        height: 140px;
    }
    .info-cover img:nth-child(1){
        border-radius:0;
    }
    .info svg:nth-child(2){
        top: 200px;
        left: 60%;
    }
    .info-cover img:nth-child(2){
        width: 110px;
        height:110px;
        top: 140px;
    }
    .info-cover svg{
        top: 18px;
        right: -10px;
        font-size: 28px;
    }
    .info-follow h1{
        padding-top: 55px;
        font-size: 20px;
    }
    .info-follow p{
        font-size: 12px;
    }
    .info-follow{
        min-height: 50%;
        padding: 0px;
    }
    .info-follow button{
        top: 180px;
        font-size: 13px;
    }
    .logout{
        position: absolute;
        top: 145px;
        left: 5px;
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 11px;
        background: linear-gradient(107deg, rgb(255, 67, 5) 11.1%, rgb(245, 135, 0) 95.3%);
        color: #fff;
        padding: 5px 10px;
        border-radius: 10px;
    }
    .logout svg{
        font-size: 18px;
    }
    .info-follow button{
        position: absolute;
        top: 145px;
        right: 5px;
        padding: 8px 5px;
        font-size: 11px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
        display: flex;
        gap: 8px;
    
    }
    .info-follow button svg{
        font-size: 18px;
    }
    .info-details{
        flex-direction: column;
        margin-top: 20px;
    }
    .info-details-list svg{
        font-size:22px;
    }
    .info-col-1{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        align-items:flex-start;
    }
    .info-details-list{
        display: flex;
        gap: 5px;
        margin-bottom: 15px;
        align-items: center;
        flex-direction: column;
    }
    .info-details-list span{
        font-size: 13px;
        text-align: center;
    }
    .info-col-2{
        width: 80%;
        background-color:#eeecec;
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
        padding: 10px 20px;
        border-radius: 10px;
    }
    .info-col-2 div{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        gap: 2px;
    }
    .info-col-2 div h2{
        font-weight: 500;
        font-size: 15px;
    }
    .info-col-2 div span{
        font-size:14px ;
        color: grey;
    }
    .profileDiv{
        width: 28px;
        height: 28px;
        background-color: #fff;
        position:absolute;
        top: 165px;
        left: 62%;
        transform: translate(-50%,-50%);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .profileDiv svg{
        font-size: 19px;
    }
    .coverDiv{
        width: 28px;
        height: 28px;
        background-color:#fff;
        position: absolute;
        top: 18px;
        right: -10px;
        transform: translate(-50%,-50%); 
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .coverDiv svg{
        font-size: 19px;
    }
    
}
