.fullScreenVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    object-fit: contain;

}
.videoContainer {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    border: 2px solid blue;
}

.videoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    border: 1px solid green;
}

.video{
    width: 100%;
    object-fit: contain;
}

.buttons {
    width: 300px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    /* Adjust for the width of the element */
    display: flex;
    justify-content: space-between;
    /* Add space between buttons */
    margin: 0 auto;
}

.button {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: rgb(112, 127, 169);
    display: flex;
    /* Add this to center the icon */
    justify-content: center;
    /* Center the icon horizontally */
    align-items: center;
    /* Center the icon vertically */
    color: white;
    border: none;
    cursor: pointer;
}

.callIcon {
    /* Here you can add a background image or use a font icon library like Font Awesome */
}

