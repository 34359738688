textarea {
    width: 100%;
    height: 120px;
    overflow: scroll;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    object-fit: contain;
}

.optionButton {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.deleteButtons {
    display: flex;
    flex-direction: column;
    justify-self: space-evenly;
}

.btn-outline {
    background-color: white;
    color: #ee2715;
    border: 2px solid rgb(112, 126, 96);
}

.btn-outline:hover {
    background-color: #ff2222;
    color: white;
    border: 2px solid #1a3344;
}