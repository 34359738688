.nav-wrapper {
    position: sticky;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}
nav{
    width: 100%;
    background-color: #0071c5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 30px;
    position: relative;
    top: 0;
    z-index: 1000;
}
.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.social-icons .nav-icons{
    font-size: 30px;
    margin: 0px 20px;
    color: #fff;
}
.social-icons .nav-icons:hover{
    transform: scale(1.2);
    transition: .3s;
}

.n-logo h1{
    font-size: 30px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif
}
.n-logo span{
    color:rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
}
.n-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
}
.n-form-button{
    width: 50%;
    display: flex;
    align-items: center;
    
}
.n-form{
    position: relative;
    width: 100%;
    flex: 1;
}
#n-search{
    width: 95%;
    height: 45px;
    outline: none;
    font-size: 19px;
    padding: 5px 45px;
    border-radius: 30px;
    border: none;
}
.search-icon{
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}
.n-profile{
    display: flex;
    align-items: center;
    justify-content:flex-end;
    flex: .2;
}
.margin{
    margin-right: 30px;
    cursor: pointer; 
}

.margin:hover{
    transform: scale(1.2);
    transition: .5s;
}

#notifi{
    position: relative;
}
#notifi span{
    position: absolute;
    top: 0px;
    left: 50%;
    background-color:#fff;
    color: black;
    border-radius: 50px;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}
@media screen and (max-width:1024px){
    nav{
        width: 100vw;
        padding: 15px 0px;
    }
    .n-logo{
        flex: 0;
    }
    .n-logo h1{
        width: 150px; 
        font-size: 24px;
        margin-left:15px;
    }
    .n-form-button{
        width: 45%;
        border-radius: 2px;
    }
    .n-img{
        width: 38px;
        height: 38px;
        border-radius: 50%;
    }
    #n-search{
        width: 100%;
        height: 40px;
        outline: none;
        font-size: 89px;
        padding: 5px 45px;
        border-radius: 2px;
        border: none;
    }
    .n-profile{
        flex: 0;
        margin-right: 15px;
    }
    #n-search{
        font-size: 22px;
        padding: 5px 10px 5px 45px;
        border: none;
    }
    .n-form svg{
        font-size: 30px;
    }
    .search-icon{
        left: 10px;
    }
    ::placeholder{
        font-size: 16px;
    }
   
    .social-icons .nav-icons{
        width: 25px;
        height: 25px;
    }

}

@media screen and (max-width:768px){
    nav{
        width: 100vw;
        padding: 12px 0px;
    }
    .n-logo{
        flex: 0;
    }
    .n-logo h1{
        width: 160px; 
        font-size: 28px;
        margin-left:15px;
    }
    .n-form-button{
        width: 55%;
        background-color: aqua;
    }
    .n-img{
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    #n-search{
        width: 100%;
        height: 50px;
        outline: none;
        font-size: 19px;
        padding: 5px 45px;
        border-radius: 8px;
        border: none;
    }
    .n-profile{
        flex: 0;
        margin-right: 15px;
    }
    #n-search{
        font-size: 22px;
        padding: 5px 10px 5px 45px;
        border: none;
    }
    .n-form svg{
        font-size: 30px;
    }
    .search-icon{
        left: 10px;
    }
    ::placeholder{
        font-size: 20px;
    }
    .social-icons{
        width: 100vw;
        background-color:orangered;
        position: fixed;
        bottom: 0px;
        color: #fff;
        display: flex;
        justify-content: space-around;
        padding: 15px 0px;
        align-items: center;
        opacity: 1;
    }
    .social-icons .nav-icons{
        width: 35px;
        height: 35px;
    }
    .openBurger{
        opacity: 1;
        font-size: 40px;
        right: 10px;
        top: 90px;
    }
}
@media screen and (max-width:430px){
    nav{
        width: 100vw;
        padding: 8px 0px;
        gap: 5px;
    }
    .n-logo{
        flex: 0;
    }
    .n-logo h1{
        width: 110px;
        font-size: 20px;
    }
    .n-img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 8px;
    }
    #n-search{
        width: 100%;
        height: 39px;
        outline: none;
        font-size: 19px;
        padding: 5px 45px;
        border-radius: 5px;
        border: none;
    }
    .n-profile{
        flex: 0;
    }
    #n-search{
        font-size: 17px;
        padding: 5px 10px 5px 35px;
        border: none;
    }
    .n-form svg{
        font-size: 23px;
    }
    .search-icon{
        left: 5px;
    }
    ::placeholder{
        font-size: 14px;
    }
    .social-icons .nav-icons{
        width: 26px;
        height: 26px;
    }
    .social-icons{
        width: 100vw;
        background-color:orangered;
        position: fixed;
        bottom: 0px;
        color: #fff;
        display: flex;
        justify-content: space-around;
        padding: 5px 0px;
        align-items: center;
        opacity: 1;
    }
    .openBurger{
        opacity: 1;
        font-size: 30px;
        right: 7px;
        top: 75px;
    }
}