@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@500;600;700;800&family=Roboto:wght@400;500;700&display=swap');
*{
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body{
    overflow-x: hidden
}
.App{
    width: 100vw;
}


