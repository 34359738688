.noti-overall {
    padding: 0px 10px;
}

.notification-group {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    padding: 5px 10px;
}

.noti-div {
    background-color: black;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 10px 0px 0px 10px;
    color: #fff;
    border: 2px solid black
}

small {
    color: grey;
}

.noti-Home-Icon {
    font-size: 30px;
}

.noti-div:hover {
    background-color: #dedede;
    color: black;
    transition: .3s;
}

.nav-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-section img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin: 20px 20px 0px 0px;
}

.notification-group h1 {
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
}

.notification-section {
    width: 100%;
    margin-top: 10px;
    background-color: #dedede;
    padding: 20px 0px 0px 20px;
    border-radius: 10px;
}

.notification-msg {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 25px;
}

.notification-msg img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.notification-msg p {
    font-size: 17px;
}

.notification-msg span {
    color: blue;
    font-size: 18px;
    text-transform: lowercase;
    cursor: pointer;
}

/* CSS */
.accept-button {
    background-image: linear-gradient(#4B9CD3, #0070C9);
    border: 0.5px solid #0077CC;
    border-radius: 7px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    direction: ltr;
    font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -.022em;
    line-height: 1.47059;
    min-width: 10px;
    overflow: visible;
    padding: 4px 15px;
    text-align: center;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}

.accept-button:disabled {
    cursor: default;
    opacity: .3;
}

.accept-button:hover {
    background-image: linear-gradient(#51A9EE, #147BCD);
    border-color: #1482D0;
    text-decoration: none;
}

.accept-button:active {
    background-image: linear-gradient(#3D94D9, #0067B9);
    border-color: #006DBC;
    outline: none;
}
.refuse-button:disabled {
    background-color: red;
    border: 0.5px solid red;
    border-radius: 7px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    direction: ltr;
    font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -.022em;
    line-height: 1.47059;
    min-width: 10px;
    overflow: visible;
    padding: 4px 15px;
    text-align: center;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: default;
    opacity: .3;
}

.accept-button:focus {
    box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
    outline: none;
}


.delete-button {
    background-image: #000000;
    border: 0.5px solid #d8eaf7;
    border-radius: 7px;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    direction: ltr;
    font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -.022em;
    line-height: 1.47059;
    min-width: 10px;
    overflow: visible;
    padding: 4px 15px;
    text-align: center;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}

.delete-button:disabled {
    cursor: default;
    opacity: .3;
}

.delete-button:hover {
    border-color: #ff8e8e;
    text-decoration: none;
}

.delete-button:active {
    outline: none;
}

.delete-button:focus {
    box-shadow: #ff8e8e 0 0 0 3px;
    outline: none;
}
@keyframes scaling {

    0%,
    100% {
        transform: scale(0.2);
        background-color: #30FFB7;
    }

    40% {
        transform: scale(1);
        background-color: #07DEFF;
    }

    50% {
        transform: scale(1);
        background-color: #0761FF;
    }
}

.loading > div {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    transform: scale(0);
    background-color: red;
    animation: scaling 2.5s ease-in-out infinite;
    display: inline-block;
    margin: .5rem;
}

.loading>div:nth-child(1) {
    animation-delay: 0s;
}

.loading>div:nth-child(2) {
    animation-delay: 0.2s;
}

.loading>div:nth-child(3) {
    animation-delay: 0.4s;
}

.loading>div:nth-child(4) {
    animation-delay: 0.6s;
}

.loading>div:nth-child(5) {
    animation-delay: 0.8s;
}

@media screen and (max-width:430px) {
    .noti-overall {
        padding: 0;
    }

    .notification-group h1 {
        font-size: 24px;
    }

    .noti-div {
        background-color: black;
        width: 45px;
        height: 45px;
        margin: 5px 0px 0px 10px;
    }

    .notification-section {
        background-color: #ffffff;
        padding: 20px 0px 0px 0px;
    }

    .noti-Home-Icon {
        font-size: 25px;
    }

    .nav-section img {
        width: 45px;
        height: 45px;
        margin: 10px 10px 0px 0px;
    }

    .notification-msg img {
        width: 40px;
        height: 40px;
    }

    .notification-msg p {
        font-size: 14px;
    }

    .notification-msg span {
        font-size: 15px;
    }

    small {}
}