.container{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(184deg, rgba(255,255,255,1) 0%, rgba(189,189,189,1) 99%);
    
}
.container-form{
    background-color:#fff;
    width:600px;
    min-height: 430px;
    border-radius: 10px;
    padding: 20px 30px 0px 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.container-form h1{
    font-weight: 500;
    background: #4B9CD3;
    color:rgba(0, 0, 0, 0);
    -webkit-background-clip:text; 
    background-clip:text; 
}
.container-form p{
   color: grey;
   font-size: 16px;
   margin-bottom: 30px;
}
.inputBox{
    width: 100%;
    height: 60px;
    position: relative;
    margin-top:30px ;
}
.inputBox input{
    width: 100%;
    height: 100%;
    padding: 5px 45px;
    background-color:transparent;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline-width: 0;
    outline: none;
    border-bottom: 1px solid grey;
}
.image, .fullName,.phone,.mail,.password{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(-50%,-50%);
    font-size: 23px;
    color: black;
}
.divBtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}
.loginBtn{
    color: #fff;
    padding: 5px 20px;
    font-size: 19px;
    border: none;
    border-radius: 5px;
    background: #4B9CD3;
    cursor: pointer;
}

.loginBtn:hover {
    background-color: #3e8e41
}

.loginBtn:active {
    background-color: #3e8e41;
    transform: translateY(4px);
}
.FG{
    color: blue;
    font-size: 14px;
    cursor: pointer;
}
.dont{
    text-align: center;
    margin-top: 40px;
}
.dont p{
    font-size: 15px;
}
.dont span{
    color: blue;
    cursor: pointer;
}

@media screen and (max-width:430px){
    .container{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background:none;
    }
    .container-form{
        background:none;
        width:600px;
        min-height: 430px;
        border-radius: 10px;
        padding: 20px 30px 0px 30px;
        box-shadow:none;
    }
    .inputBox input{
        padding: 5px 35px;
    }
   
}
@media screen and (max-width:1024px){
    .container{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffff;
    }
}