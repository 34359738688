.i-form{
    width: 100%;
    min-height: 250px;
    margin: 20px auto 30px;
    padding: 5px 30px 30px 30px;
    border-radius: 20px;
    background-color: #ffffff;
}

.i-input-box{
    width: 100%;
    position: relative;
}
.i-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
}
#i-input{
    width: 100%;
    height: 120px;
    padding: 0px 70px;
    border-radius: 20px 20px 0px 0px;
    border: none;
    border-bottom: 2px solid rgb(214, 205, 205);
    outline: none;
    font-size: 20px;
    background-color: transparent; 
}

.file-upload{
    min-width: 100%;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    gap: 30px;
    flex-wrap: wrap;
}
.file-upload .file-icons{
    display: flex;
    gap: 70px;
    flex: 1.5;
}
.file-upload button{
    padding: 15px 60px;
    border-radius: 40px;
    border:none;
    background: #4B9CD3;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}
.photo-icon{
    color: red;
}
.pv-upload{
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
}
.photo-dis{
    font-weight: 500;
    padding: 0px 4px;
}
.displayImgs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Creates three equal columns */
    gap: 10px;
    /* Adds a gap between grid items */
    width: 100%;
    min-height: 250px;
    position: relative;
    padding: 13px;
    margin-top: 30px;
}

.displayImgs img {
    width: 100%;
    /* The image will take the full width of the grid cell */
    height: 100%;
    /* The image will take the full height of the grid cell */
    object-fit: contain;
    object-position: center;
    border-radius: 10px;
}

.img svg {
    position: relative;
    top: 3px;
    background-color: black;
    color: #fff;
    border-radius: 50%;
}


@media screen and (max-width:1024px){
    .i-form{
        width: 100%;
        margin: 10px auto 30px;
        padding: 5px 15px 20px 15px;
        border-radius: 10px;
        background-color: #ffffff;
    }
    #i-input{
        height: 90px;
        padding: 0px 10px 0px 60px;
        border-bottom: 2px solid rgb(214, 205, 205);
        font-size: 18px;
    }
    .file-upload{
        margin-top: 20px;
    }
    .file-upload .file-icons{
        display: flex;
        justify-content: space-between;
        gap: 15px;  
        flex: 0;
    }
    .file-upload button{
        padding: 10px 30px;
        border-radius: 10px;
        font-size: 15px;
    }
    .photo-dis{
        font-weight: 500;
        padding: 0px 4px;
        font-size: 15px;
    }
    .i-img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .displayImg{
            width: 80%;
            height: 220px;
            padding: 10px;
            margin-top: 10px;
    }
}
@media screen and (max-width:768px){
    .file-upload .file-icons{
        display: flex;
        gap: 20px; 
        
    }
    .i-form{
        margin: 10px auto 30px;
        min-height: 190px;
       
    }
    .displayImg{
        width: 80%;
        height: 220px;
        padding: 10px;
        margin-top: 0px;
}
}
@media screen and (max-width:430px){
    .i-form{
        width: 100%;
        min-height: 100px;
        margin: 0px auto 30px;
        padding: 5px 15px 15px 15px;
        border-radius: 0px;
        background-color: rgb(255, 255, 255);
        border-bottom: 1px solid #efefef;
    }
    #i-input{
        font-size: 13px;
        height: 75px;
        padding: 0px 20px 0px 60px;
    }
    .i-input-box ::placeholder{
        font-size: 13px;
    }
    .file-upload{
        margin-top: 10px;
    }
    .file-upload button{
        padding: 5px 20px;
        border-radius: 5px;
        background-color:#4B9CD3;
        color: #fff;
        font-size: 15x;
    }
    .photo-dis{
        display: none;
    }
    .pv-upload .input-svg{
        width: 24px;
    }
    .file-upload .file-icons{
        display: flex;
        gap: 20px;  
    }
    .i-img{
        width: 50px;
        border-radius: 50%;
        position: absolute;
        z-index: 100;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
    }
    .displayImg{
        width: 100%;
        height: 200px;
        padding: 5px;
        margin-top: 10px;
    }
}