.noti-overall{
    padding: 0px 10px;
}
.notification-group{
    max-width: 850px;
    display: flex;
    flex-direction: column;
    margin:20px auto;
    padding: 5px 10px;
}
.noti-div{
    background-color: black;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 10px 0px 0px 10px;
    color: #fff;
    border: 2px solid black
}
small{
    color: grey;
}
.noti-Home-Icon{
    font-size: 30px;
}
.noti-div:hover{
    background-color: #dedede;
    color: black;
    transition: .3s;
}
.nav-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-section img{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin: 20px 20px 0px 0px;
}
.notification-group h1{
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
}
.notification-section{
    width: 100%;
    margin-top: 10px;
    background-color: #dedede;
    padding: 20px 0px 0px 20px;
    border-radius: 10px;
}
.notification-msg{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 25px;
}
.notification-msg img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.notification-msg p{
    font-size: 17px;
}
.notification-msg span{
    color: blue;
    font-size: 18px;
    text-transform: lowercase;
    cursor: pointer;
}
@media screen and (max-width:430px){
    .noti-overall{
        padding: 0;
    }
    .notification-group h1{
        font-size: 24px;
    }
    .noti-div{
        background-color: black;
        width: 45px;
        height: 45px;
        margin: 5px 0px 0px 10px;
    }
    .notification-section{
        background-color: #ffffff;
        padding: 20px 0px 0px 0px;
    }
    .noti-Home-Icon{
        font-size: 25px;
    }
    .nav-section img{
        width: 45px;
        height: 45px;
        margin: 10px 10px 0px 0px;
    }
    .notification-msg img{
        width: 40px;
        height: 40px;
    }
    .notification-msg p{
        font-size: 14px;
    }
    .notification-msg span{
        font-size: 15px;
    }
    small{

    }
}