
@media screen and (max-width:1024px){
    .M-features{
        width:100%;
        margin-bottom: 100px;
    }
}

@media screen and (max-width:768px){
    .M-features{
        min-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 85px;
        margin-bottom: 100px;
        
    }
}
@media screen and (max-width:430px){
    .M-features{
        min-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 50px;
        margin-bottom: 100px;
    }
}