.div-friends-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    /* adjust as needed */
}

.friend-header {
    width: 100%;
    display: flex;
}

.friend-item{
    display: flex;
    width: 73%;
    min-height: 70px;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 15px 15px 15px 15px;
    border-radius: 10px;
    background-color: #fff;
}

.friend-item img { 
    width: 70px;
    height: 70px;
    border-radius: 50%;
    top: 240px;
    left: 50%;
    object-fit: cover;
    object-position: center;
    padding: 3px;
    background-color: #fff;
    cursor: pointer;
}

.friend-item h3{
    margin-top: 30px 30px;
    align-items: center;
}

select{
    border: none;
    font-size: medium;
}